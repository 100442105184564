@font-face {
  font-family: 'RubikLight';
  src: local('RubikLight'), url(./assets/font/web/Rubik/static/Rubik-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'RubikRegular';
  src: local('RubikRegular'), url(./assets/font/web/Rubik/static/Rubik-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: RubikRegular, 'Segoe UI', 'RubikRegular', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
